@font-face {
    font-family: 'CustomHelveticaNeue';
    src: url('assets/fonts/HelveticaNeueLTW04-55Roman.eot');
    /* IE9 Compat Modes */
    src:
        url('assets/fonts/HelveticaNeueLTW04-55Roman2.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('assets/fonts/HelveticaNeueLTW04-55Roman.woff') format('woff'),
        /* Pretty Modern Browsers */
        url('assets/fonts/HelveticaNeueLTW04-55Roman.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('assets/fonts/HelveticaNeueLTW04-55Roman.svg') format('svg');
    /* Legacy iOS */
}

body {
    margin: 0;
    font-family: 'CustomHelveticaNeue';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #f8f8f8;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

html,
body,
#root {
    height: 100%;
}

.ReactModalPortal {
    position: relative;
    z-index:  101;
}
