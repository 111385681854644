.subList {
    counter-increment: subList !important;
  }
  .subList::before {
    content: counter(subList, lower-alpha) "." !important;
    list-style-type: lower-alpha !important;
  }
  .parentList {
    counter-increment: parentList !important;
  }
  .parentList:before {
    content: counter(parentList) "." !important;
    list-style-type: decimal
  }
