
#chatHeader h1 {
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 20px;
    color: white;
    display: table-cell;
    padding: 0px 0px 0px 20px;
    width: 70%;
    float: left;
}    
#webchat {
    margin: 0px;
    top: 70px;
    right: 20px;
    bottom: 100px;
    left: auto;
    position: fixed;
    background-color: white;
    height: auto;
    width: 400px;
}
